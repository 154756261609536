@charset "utf-8";

$link-color: #22a !default;
$text-color: #444 !default;

@import "minimal-mistakes/skins/contrast"; // skin
@import "minimal-mistakes"; // main partials

.site-title img {
    margin: 0 1rem;
}

.references {
    h1 {
        display: none;
    }
    .archive__item-teaser img {
        height: 360px;
    }
    .feature__wrapper {
        border: none;
        margin-bottom: 0px; 
    }
}
    
@media (min-width: 37.5em) {
    .app {
        #left {
            float: left;
            width: 45%;
        }
        #right {
            float: right;
            width: 45%;
            h3 {
                margin-top: 0px;
            }
        }
    }
}

.app {
    .app-icon {
        width: 1.5em;
    }
    #right {
        li {
            margin-bottom: 0px;
        }
    }
    .play-badge {
        height: 64px;
    }
    #screenshots {
        clear: both;
    }
    #contact {
        clear: both;
        padding-top: 1.5em;
        text-align: center;
        border-top: 1px solid gray;
        .btn {
            margin-left: 1em;
        }
    }
}

.contact {
    font-size: larger;
    table {
        tr {
            margin-bottom: 1em;
            td {
                border: none;
                vertical-align: top;
                padding: 0.5em;
            }
            td:first-child {
                text-align: right;
                font-weight: bold;
            }
        }
    }
}

.page__hero-caption{
    font-family: sans-serif;
}

.layout--blog{
    .archive__item{
        margin-top: 2em;
    }
    .archive__item-teaser{
        background-position: center;
        margin-bottom: 0.5em;
    }
    
    .archive__item-title-wrapper {
        margin-top: 6em;
        margin-bottom: 0px;
        padding: 1.2em;
        a{
            color: white;
        }
        .archive__item-title {
            margin: 0px;
        }
        .archive__date {
            margin: 0px;
            margin-top: 0.5em;
            color: lightgray;
            font-size: small;
            font-weight: bold;
        }
    }
}

.social-icons .fa-fw {
    color: inherit;
}

.references {
    h2.archive__item-title {
        font-size: 1em;
    }
}

.btn--dark {
  background-color: #333333;
  color: #fff;
}


